import React, { useState, useContext, useEffect } from "react";
import { Translation } from "react-i18next";
import i18next from "i18next";
import { api_get } from "../../API.js";
import PersonContext from "../../PersonContext.js";
import { isTest, getApiHost, getDomainCountry } from "../../config.js";
import { Loading } from "../Components.js";

export const Invoices = (props) => {
    let context = useContext(PersonContext);
    let [list, setList] = useState([]);
    let [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if(context?.person?.personId?.externalId == null) {
            return;
        }
        api_get(
            "/persons/" + context.person.personId.externalId + "/invoices"
        ).then((resp) => {
            setList(resp);
            setLoading(false);
        });
    }, [context]); // eslint-disable-line

    return (
        <Translation>
            {(t) =>
                isLoading ? (
                    <div className="d-flex justify-content-center mt-4 mb-4">
                        <Loading>{t("Settings.LoadingDetails")}</Loading>
                    </div>
                ) : (
                    <React.Fragment>
                        <ul className="list list-toggle">
                            {list.map((item, index) => {


                                return item.type === 'invoice' ? (
                                        <ListItemInvoice item={item} key={index} isOpen={index===0?true:false} />
                                    ) : (
                                        <ListItemTransaction item={item} key={index} isOpen={index===0?true:false} />
                                    );
                            })}
                        </ul>
                        {!list.length ? (
                            <div className="mt-4 mb-4">
                                <p className="body-text">
                                    {t("Settings.InvoicesNoFound")}
                                </p>
                            </div>
                        ) : null}
                    </React.Fragment>
                )
            }
        </Translation>
    );
};


const ListItemInvoice = (props) => {
    let context = useContext(PersonContext);
    let [isOpen, setIsOpen] = useState(props.isOpen || false);
    let item = props.item;

    let dueDate = new Date(item.dueDate);
    let month = dueDate.getMonth();
    let year = dueDate.getFullYear();
    item.monthName = i18next.t("MonthNames." + month)

    if (isOpen && isTest()) {
        console.log(props.item);
    }
    return (
        <li>
            <div onClick={() => { setIsOpen(!isOpen); }}>
                <div>
                    <span className="body-text color-black">
                        {
                        //i18next.t("Settings.InvoicesLabel")
                        //+ " " + year
                        item.monthName
                        + " " + year
                        }
                        <StateTextInList item={item} />
                    </span>
                </div>
                <div className="text-end">
                    <span
                        className="span-medium"
                        dangerouslySetInnerHTML={{
                            __html: item.invoiceAmountFormated,
                        }}
                    ></span>
                </div>
                <div className="icon toggle-down">
                    <span></span>
                </div>
            </div>
            {isOpen ? (
                <div className="more-info mt-3">
                    {
                        item.state === 'FAILED' && (item.clearingHouseType === 'EFT' || item.clearingHouseType === 'CREDIT_CARD')
                        ? (
                            <div>
                                <p className="caption-text mb-4">
                                {i18next.t("Settings.InvoicesFailedInfo." + item.clearingHouseType)}
                                </p>
                            </div>
                        ) 
                        : null
                    }
                    <div>
                        <div className="d-flex info-row justify-content-between">
                            <div>
                                <span className="caption-text-regular color-rock">{i18next.t("Settings.InvoicesDetailsDate")}</span>
                            </div>
                            <div className="text-end">
                                <span className="caption-text-regular color-black">
                                    {item.deductionDate}
                                </span>
                            </div>
                        </div>
                        {
                        item.state === 'PAID'
                        ? (
                            <div className="d-flex info-row justify-content-between">
                                <div>
                                    <span className="caption-text-regular color-rock">{i18next.t("Settings.InvoicesDetailsClearingHouse")}</span>
                                </div>
                                <div className="text-end">
                                    <span className="caption-text-regular color-black"><ClearingHouse item={item} /></span>
                                </div>
                            </div>
                        ) 
                        : null
                        }
                        <div className="d-flex info-row justify-content-between">
                            <div>
                                <span className="caption-text-regular color-rock">{i18next.t("Settings.InvoicesDetailsState")}</span>
                            </div>
                            <div className="text-end">
                                <span className="caption-text-regular color-black">
                                    <StateText item={item} />
                                </span>
                            </div>
                        </div>

                        <div className="d-flex info-row justify-content-between">
                            <div>
                                <span className="caption-text-regular color-rock">{i18next.t("Settings.InvoicesDetailsExVat")}</span>
                            </div>
                            <div className="text-end">
                            <span
                                className="caption-text-regular color-black"
                                dangerouslySetInnerHTML={{
                                    __html: item.invoiceAmountExVatFormated,
                                }}
                            ></span>
                            </div>
                        </div>

                        <div className="d-flex info-row justify-content-between">
                            <div>
                                <span className="caption-text color-rock">{i18next.t("Settings.InvoicesDetailsVat")}</span>
                            </div>
                            <div className="text-end">
                            <span
                                className="caption-text-regular color-black"
                                dangerouslySetInnerHTML={{
                                    __html: item.vatAmountFormated,
                                }}
                            ></span>
                            </div>
                        </div>

                        <div className="d-flex info-row justify-content-between">
                            <div>
                                <span className="caption-text-regular color-rock">{i18next.t("Settings.InvoicesDetailsTotal")}</span>
                            </div>
                            <div className="text-end">
                            <span
                                className="caption-text-regular color-black"
                                dangerouslySetInnerHTML={{
                                    __html: item.invoiceAmountFormated,
                                }}
                            ></span>
                            </div>
                        </div>

                        <div className="d-flex info-row justify-content-between">
                            <div>
                                <span className="caption-text-regular color-rock">{i18next.t("Settings.InvoicesDetailsAmount")}</span>
                            </div>
                            <div className="text-end">
                            <span 
                                className="caption-text-regular color-black"
                                dangerouslySetInnerHTML={{
                                    __html: item.invoiceAmountFormated,
                                }}
                            ></span>
                            </div>
                        </div>

                        {
                        context.center.countrycode === 'SE' || context.center.countrycode === 'DE'
                        ? (
                            <div className="mt-4  mb-4">
                                <a
                                    href={
                                        getApiHost() +
                                        "/persons/" +
                                        context.person.personId.externalId +
                                        "/invoices/" +
                                        item.paymentRequestSpecificationId
                                            .subId +
                                        "/" +
                                        item.paymentRequestSpecificationId
                                            .center +
                                        "/" +
                                        item.paymentRequestSpecificationId.id +
                                        "?AccessToken=" +
                                        context.accessToken + 
                                        "&DomainCountry=" +
                                        getDomainCountry()
                                    }
                                    className="link-download-invoice color-primary"
                                >
                                    {i18next.t(
                                        "Settings.InvoicesDownloadInvoice"
                                    )}
                                </a>
                   
                            </div>
                        )
                        : null
                        }
                    </div>
                </div>
            ) : null}
        </li>
    );
};

const StateTextInList = ({item}) => {
    /*
    PAID: Betald
    PARTIAL: Delvis betald
    SENT: Skickad
    NEW: Ny
    FAILED: Misslyckad
    CANCELLED: Makulerad
    */
    if (item.state === 'SENT' || item.state === 'NEW') {
        return <span className="span-small color-rock"> ({i18next.t("Settings.InvoicesStateTextSENT").toLowerCase()})</span>;
    } else if (item.state === 'PAID' || item.state === 'CANCELLED') {
        return <span className="span-small color-rock"> ({i18next.t("Settings.InvoicesStateText" + item.state).toLowerCase()})</span>;
    } else if (item.state === 'FAILED' && typeof item.deptCollectionDate !== 'undefined') {
        return <span className="span-small color-rock"> ({i18next.t("Settings.InvoicesStateTextPAID").toLowerCase()})</span>; // + ' ' + item.deptCollectionDate.substring(0, 10)
    } else {
        return <span className="span-small color-attention"> ({i18next.t("Settings.InvoicesStateText" + item.state).toLowerCase()})</span>;
    }
}

const StateText = ({item}) => {
    /*
    PAID: Betald
    PARTIAL: Delvis betald
    SENT: Skickad
    NEW: Ny
    FAILED: Obetald
    CANCELLED: Makulerad
    */
    if (item.state === 'SENT' || item.state === 'NEW') {
        return i18next.t("Settings.InvoicesStateTextSentExtended", {month: item.monthName});
    } else if (item.state === 'PAID' || item.state === 'CANCELLED') {
        return i18next.t("Settings.InvoicesStateText" + item.state);
    } else if (item.state === 'FAILED' && typeof item.deptCollectionDate !== 'undefined') {
        return i18next.t("Settings.InvoicesStateTextPAID") + ' ' + item.deptCollectionDate.substring(0, 10);
    } else {
        return i18next.t("Settings.InvoicesStateText" + item.state);
    }

};

const ClearingHouse = (props) => {
    if (props.item.clearingHouseType === "EFT") {
        return i18next.t("Settings.InvoicesClearingHouseTypeEFT");
    } else if (props.item.clearingHouseType === "CREDIT_CARD") {
        return i18next.t("Settings.InvoicesClearingHouseTypeCREDITCARD");
    } else if (props.item.clearingHouseType === "INVOICE") {
        return i18next.t("Settings.InvoicesClearingHouseTypeINVOICE");
    }
};

const ListItemTransaction = (props) => {
    //if (isTest()) {
    //    console.log(props.item);
    //}
    let context = useContext(PersonContext);
    let [isOpen, setIsOpen] = useState(props.isOpen || false);
    let item = props.item;
    return (
        <li>
            <div onClick={() => { setIsOpen(!isOpen); }}>
                <div>
                    <span className="body-text">
                        <TransactionLabel item={item} />
                    </span>
                </div>
                <div className="text-end">
                    {typeof item.deposit !== "undefined" ? (
                        <span
                            className="span-medium"
                            dangerouslySetInnerHTML={{
                                __html: item.depositFormated,
                            }}
                        ></span>
                    ) : typeof item.withdrawn !== "undefined" ? (
                        <span
                            className="span-medium"
                            dangerouslySetInnerHTML={{
                                __html: item.withdrawnFormated,
                            }}
                        ></span>
                    ) : null}
                </div>
                <div className="icon toggle-down">
                    <span></span>
                </div>
            </div>
            {isOpen ? (
                <div className="more-info mt-3">
                    <div>
                        <div className="d-flex info-row">
                            <div>
                                <span className="caption-text color-rock">{i18next.t("Settings.InvoicesDetailsDate")}</span>
                            </div>
                            <div>
                                <span className="caption-text color-black">
                                    {item.bookTime}
                                </span>
                            </div>
                        </div>
                        {
                            item.text !== 'API Sale Transaction'
                            ? (
                            <div className="d-flex info-row">
                                <div>
                                    <span className="caption-text color-rock">{i18next.t("Settings.InvoicesDetailsInfo")}</span>
                                </div>
                                <div>
                                    <span className="caption-text color-black">
                                        {item.text}
                                    </span>
                                </div>
                            </div>
                            )
                            : null
                        }
                        

                        {typeof item.deposit !== "undefined" ? (
                            <React.Fragment>
                                <div className="d-flex info-row">
                                    <div>
                                        <span className="caption-text color-rock">
                                        {i18next.t("Settings.InvoicesDetailsPaymentMethod")}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="caption-text color-black">
                                            <PaymentMethod item={item} />
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex info-row">
                                    <div>
                                        <span className="caption-text color-rock">
                                            {i18next.t("Settings.InvoicesDetailsExVat")}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="caption-text color-black" dangerouslySetInnerHTML={{
                                            __html: item.depositExVatFormated,
                                        }}>
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex info-row">
                                    <div>
                                        <span className="caption-text color-rock">
                                            {i18next.t("Settings.InvoicesDetailsVat")}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="caption-text color-black" dangerouslySetInnerHTML={{
                                            __html: item.vatAmountFormated,
                                        }}>
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex info-row">
                                    <div>
                                        <span className="caption-text color-rock">
                                        {i18next.t("Settings.InvoicesDetailsTotal")}
                                        </span>
                                    </div>
                                    <div>
                                        <span className="caption-text color-black" dangerouslySetInnerHTML={{
                                            __html: item.depositFormated,
                                        }}>
                                        </span>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : null}
                        {item.info.indexOf("Actiway") >= 0 ||
                        item.info.length === 16 ? (
                            <div className="mt-4 mb-4">
                               
                                    <a
                                        href={
                                            getApiHost() +
                                            "/persons/" +
                                            context.person.personId.externalId +
                                            "/transactionreceipt/" +
                                            item.info +
                                            "?AccessToken=" +
                                            context.accessToken + 
                                            "&DomainCountry=" +
                                            getDomainCountry()
                                        }
                                        className="link-download-invoice color-primary"
                                    >
                                        {i18next.t(
                                            "Settings.InvoicesDownloadInvoice"
                                        )}
                                    </a>
                                
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </li>
    );
};

const PaymentMethod = (props) => {
    if (props.item.keyName.indexOf("INVOICE") >= 0) {
        return "Faktura";
    } else if (props.item.keyName.indexOf("Actiway") >= 0) {
        return "Actiway";
    } else {
        return "Kreditkort";
    }
};

const TransactionLabel = (props) => {
    let info = props.item.info;
    let deposit = (typeof props.item.deposit !== "undefined") ? props.item.deposit : false;
    let withdrawn = (typeof props.item.withdrawn !== "undefined") ? props.item.withdrawn : false;

    if (info.indexOf("Actiway") >= 0) {
        // Actiway
        return i18next.t("Settings.InvoicesTransactionLabelActiway");

    } else if (deposit !== false && info.length === 16) {
        // Förskottsbetalning
        return i18next.t("Settings.InvoicesTransactionLabelPrepay");

    } else if (withdrawn !== false && props.item.keyName === "TYPE_INVOICE") {
        // Faktura
        return i18next.t("Settings.InvoicesTransactionLabelInvoice");

    } else if (deposit !== false && props.item.keyName === "TYPE_INVOICE_CALLBACK" ) {
        // Återbetalning
        return i18next.t("Settings.InvoicesTransactionLabelCallback");

    } else if (typeof props.item.deposit !== "undefined") {
        // Inbetalning // Förskottsbetalning
        return i18next.t("Settings.InvoicesTransactionLabelPrepay");
        //return i18next.t("Settings.InvoicesTransactionLabelDeposit");

    } else if (typeof props.item.withdrawn !== "undefined") {
        // Dragning
        return i18next.t("Settings.InvoicesTransactionLabelWithdraw");

    } else {
        return "";
    }

};
